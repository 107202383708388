.ClientRequestContainer {
    position: relative;
    flex: 1;
    padding: 2rem;
    overflow-y: scroll;
}

.ContainerTitle {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: .1rem;
}
.topContainer{
    display: flex;
}
.pdfContainer{
    padding: 5rem;
}
.Summary {
    margin: 2rem 1rem;
    width: 30%;
}

.CardContent {
    margin: 1rem;
    padding: 1rem;
    position: relative;
}

.CardTitle {
    color: var(--color-strong-green);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1px;
    
}
.CardSubTitle{
    color: var(--color-grey-dark-1);
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 1px;
    width: 200px;
}
.TextItem {
    margin: 0 0 .2rem .5rem;
    font-size: 1.6rem;
}

.TitleGrower{
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    border-bottom: var(--line);
}
.delete{
    position: absolute;
    bottom: 1rem;
    right: 0;
}
.deleteModal{
    width: 100%;
    text-align: center;
}
.deleteText{
    width: 30rem;
    font-size: 2rem;

}
.deleteIcon{
    font-size: 2.5rem;
    color: var(--color-grey-light-4);
}
.deleteIcon:hover{
    cursor: pointer;
    color: var(--color-red);
}
.TitleField{
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding: 0 0 0 1rem;
}
.Logo {
    background-color: var(--color-grey-light-1);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin:1rem;
}

.MyImg {
    max-width: 100%;
    max-height: 100%;
    padding: .8rem;
}

.imgContainer{
    display: flex;
    padding: 2rem;
    height: 40rem;
    margin-bottom: 2rem;
    border-bottom: var(--line);
    
} 
.img_container{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 4rem;
}
.itemImg{
    
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.testItem{
    position: relative;
    width: 90%;
    height: 80%;
    background-color: var(--color-grey-light-2);
}
.footImg{
    height: 20%;
    width: 90%;
    background-color: var(--color-grey-light-1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.titleFoot{
    font-size: 2.4rem;

}

.img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    max-height: 95%;
}

/* .imgContainer{
    display: flex;
    padding: 2rem;
    height: 35rem;
    margin-bottom: 2rem;
    border-bottom: var(--line);
} */
/* .itemImg{
    position: relative;
    flex: 0 0 33.33%;
    padding: 1rem;
} */
.Rotate{
    transform: rotate(-90deg);
}
/* .someImg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    max-height: 100%;
} */
.ManifoldContainer{

    margin-bottom: 1.8rem;
}
.ManifoldTable{
    display: flex;
    flex: 0 0 80%;
    border-bottom: var(--line-orange);
    
}
.Column{
    margin: 2rem;
    flex: 1;
    font-size: 1.6rem;
    
}

.RowManifold{
    display: flex;
    flex:1;
    border: var(--line);
}
.LabelManifold{
    padding: 1rem;
    flex:1;
    font-weight: 700;
    background-color: var(--color-grey-light-2);
}
.ContentManifold{
    flex: 1;
    padding: 1rem;
}

.Warnings{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.ModalWarn{
    display: flex;
    flex-direction: column;
    padding: .5rem;
}
.Comment{
    margin-top: 1rem;
    border-top: var(--line-orange);
    padding: 1rem;
    text-align: center;
    font-size: 1.6rem;
}

/* TABLE */
.tableContainer {
    margin: 3rem 0;
    width:50%;
    /* overflow-y: scroll; */
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}


.table td:first-child {
    font-weight: 400;
    background-color: var(--color-orange);
    color: white;
}
.table td {
    
    font-size: 1.6rem;
    padding: .8rem;
    width: 50%;
}
.table td:last-child {
    border: 1px solid #ddd;
}


.reportName{
    font-size: 2rem;
    margin-left: 3rem;
}
.Button{
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 1.6rem;
    padding: 10px;
    margin: 10px;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 17px 21px 66px -26px rgba(0,0,0,0.51);
    background-color: #4A00E0;
}

.graphContainer{
    padding: 5rem;
}
.graphTitle{
    font-family: 'Roboto';
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 2rem;
}
.barManifold{
    padding: 1rem 6rem;
    border-top: var(--line-3);
    border-bottom: var(--line-3);
    margin-bottom: 1rem;
    font-weight: 300;
}
.barManifold:hover{
    cursor: pointer;
    background-color: rgba(3, 89, 201, .3);
}
.barManifoldTitle{
    font-family: 'Roboto';
    font-size: 1.8rem;
    
    
}
.barSelected{
    background-color: rgba(3, 89, 201, .3);
    font-weight: 500;
}

.manifoldPictures{
    padding-top: 4rem;
}

.inputElement {
    font-family: 'Lato';
    color: var(--color-grey-dark-1);
    margin: 1rem 0;
    display: block;
    border-radius: 2px;
    border: 0;
    padding: 1rem 1rem;
    background-color: var(--color-grey-light-2);
    
    font-size: 1.5rem;
    border-bottom: 2px solid transparent;
    width: 100%;
}

.inputElement:focus {
    outline: none;
    border-bottom: 2px solid var(--color-strong-green);
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
}
.title{
    
    font-size: 2rem;
    font-family: 'Roboto';
    font-weight: 700;
    color: var(--color-grey-dark-1);
    margin-bottom: 2rem;
    letter-spacing: 1px;

}