.Button{
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 1.6rem;
    padding: 10px;
    margin: 10px;
    font-weight: 500;
    border-radius: 6px;
    box-shadow: 17px 21px 66px -26px rgba(0,0,0,0.51);
    letter-spacing: 1px;
    
}

.Succes{
    background-color: var(--color-strong-green);
}

.Save{
 background-color: #4A00E0;
}
.Orange{
    background-color: var(--color-orange);
   }
.Danger{
    background-color: #ED213A;
}
.Blue{
    background-color: var(--color-blue);
}

.Disabled
{
    background-color: grey;
    cursor: not-allowed;
    
}
.Soft{
    background-color: var(--color-grey-dark-3);
}

.Button:hover{
    opacity: .8;
}