.CreateReportScreenContainer {
  flex: 1;
  padding: 2rem;
  position: relative;
  align-items: center;
  overflow-y: scroll;
}

.SelectBox {
  display: flex;
  flex-direction: column;
  width: 30%;
  position: relative;
  font-size: 1.5rem;
  margin: 1rem;
}

.OptionContainer {
  box-sizing: border-box;
  order: 1;
  background: #2f3640;
  color: #f5f6fa;
  width: 100%;
  opacity: 0;
  max-height: 0;
  transition: all 0.4s;
  border-radius: .5rem;
  overflow: hidden;
  position: absolute;
  top: 5rem;
  left: 0;
}

.Selected {
  order: 0;
  background: #2f3640;
  color: #f5f6fa;
  width: 100%;
  font-size: 1.5rem;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  margin-bottom: 8rem;
}

.radio {
  display: none;
}

.Active {
  max-height: 200px;
  opacity: 1;
  overflow-y: scroll;
}

.SelectBox .option:hover {
  background: #414b57;
}

.SelectBox .option, .Selected {
  padding: 1.5rem 1.6rem;
  cursor: pointer;
}

.SelectBox label {
  cursor: pointer;
}

.SelectBox .OptionContainer::-webkit-scrollbar {
  width: .8rem;
  background: #0d141f;
  border-radius: .5rem;
}

.SelectBox .OptionContainer::-webkit-scrollbar-thumb {
  background: #525861;
  border-radius: .5rem;
}

.Selected::after {
  content: "";
  background: url('./resources/arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 32px;
  right: 10px;
  top: 5px;
  transition: all 0.4s;
}

.OptionContainer.Active+.Selected::after {
  transform: rotateX(180deg);
  top: -6px;
}

.SelectsContainer {
  display: flex;
}

/*  FIELD CONTAINERS*/

.CardContainer {
  margin-top: 1rem;
  padding: 1rem;
}

.DetailContainer {
  display: flex;
  align-items:center;
  /* justify-content: center; */
  padding-left: 2rem;
}

.ContainerTitle {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: .1rem;
}

.CardTitle {
  /* color: var(--color-strong-green); */
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: .8rem;
  padding: .5rem;
}

.FieldsFilteredContainer {
  transition: all 0.4s;
}

.Details {
  color: var(--color-grey-dark-1);
  flex: 0 0 20%;
  
}
.Details:first-child{
  border-right: 1px solid var(--color-grey-light-4);
}
.ButtonHolder{
  flex: 0 0 10%;
  display: flex;
  flex-direction: column;
}
.Resume {
  flex: 0 0 20%;
}

.Reports {}

.TextItem {
  margin: 0 0 .2rem .5rem;
  font-size: 1.6rem;
}

.TableItem {
  padding: .2rem .5rem;
}

.TableRow {
  margin-bottom: var(--line);
}

.RowReports {
  display: flex;
  border-bottom: var(--line);
  align-items: center;
}

.itemReports {
  flex: 0 0 20%;
  padding: .2rem .5rem;
}

.ReportsMonthlyContainer {
  display: flex;
}

.ReportsMonthly {
  display: flex;
  flex-direction: column;
}

.itemReportsMonthly {
  /* flex: 0 0 25%; */
  padding: .2rem .5rem;
}