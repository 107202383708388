.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
    width: 250px;
    
    border-radius: 5px;
    position: relative;
  }

  .card_landscape {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    transition: 0.3s;
    width: 400px;
    border-radius: 5px;
    position: relative;
  }
  
  .card:hover {
   
  }
  
  .img_card {
    border-radius: 5px 5px 0 0;
    width:250px;
    height: 300px;
  }
  .img_card_landscape{
    border-radius: 5px 5px 0 0;

    width:400px;
    height: 300px;
  }
  .container {
    padding: 20px;
    height: 70px;
  }

  .container p{
      font-size: 1.6rem;
      font-family: 'Roboto';
      color: var(--color-strong-green);
      letter-spacing: 1px;
      
  }
  .waterMark{
    position: absolute;
    top:10px;
    right: 10px;
  }
  .waterMark p{
    font-size: 1.2rem;
    font-family: 'Roboto';
    color: var(--color-yellow-1);
    letter-spacing: 1px;
  }