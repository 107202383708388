:root {
  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-green: #1D976C;
  --color-orange: #F7971E;
  --color-strong-green: #0b7751;
  --color-blue: #2F80ED;
  --color-blue-2: #0359c9;
  --color-primary: #2f4050;
  --color-purple: #363795;
  --color-red: #F41E1E;
  --color-yellow-1: yellow;
  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, .3);
  --shadow-light: 0 2rem 5rem rgba(0, 0, 0, .06);
  --line: 1px solid var(--color-grey-light-2);
  --line-3: 1px solid var(--color-grey-dark-3);
  --line-orange: 1px solid var(--color-orange);
}

* {
  margin: 0;
  padding: 0;
}

*, *::after, *::before {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  background-color: var(--color-grey-light-1);
  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 1.2rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--color-green);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-strong-green);
}