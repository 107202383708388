.SelectBox {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-right: 2rem;
    margin-bottom: 1rem;
    position: relative;
    font-size: 1.5rem;
    
  }
  
  .OptionContainer {
    box-sizing: border-box;
    order: 1;
    background: #3d4653;
    color: #f5f6fa;
    width: 100%;
    opacity: 0;
    max-height: 0;
    transition: all 0.4s;
    border-radius: .5rem;
    overflow: hidden;
    position: absolute;
    top: 5rem;
    left: 0;
    z-index: 500;
  }
  
  .Selected {
    order: 0;
    background: #2f3640;
    color: #f5f6fa;
    width: 100%;
    font-size: 1.5rem;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    
  }
  
  .radio {
    display: none;
  }
  
  .Active {
    max-height: 200px;
    opacity: 1;
    overflow-y: scroll;
  }
  
  .SelectBox .option:hover {
    background: #414b57;
  }
  
  .SelectBox .option, .Selected {
    padding: 1.5rem 1.6rem;
    cursor: pointer;
  }
  
  .SelectBox label {
    cursor: pointer;
  }
  
  .SelectBox .OptionContainer::-webkit-scrollbar {
    width: .8rem;
    background: #0d141f;
    border-radius: .5rem;
  }
  
  .SelectBox .OptionContainer::-webkit-scrollbar-thumb {
    background: #525861;
    border-radius: .5rem;
  }
  
  .Selected::after {
    content: "";
    background: url('./resources/arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 32px;
    right: 10px;
    top: 5px;
    transition: all 0.4s;
  }
  
  .OptionContainer.Active+.Selected::after {
    transform: rotateX(180deg);
    top: -6px;
  }
  
  .SelectsContainer {
    display: flex;
  }