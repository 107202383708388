.SquareCard{
  
    background-color: white;
    box-shadow: var(--shadow-dark);
    border-radius: .6rem;
    transition: all .1s;
    
    /* border-bottom: 2px solid transparent; */
    
}
.Bordered{
    border-left:6px solid var(--color-orange); 
}
.Purple{
    border-left:6px solid var(--color-purple); 
}
.Green{
    border-left:6px solid var(--color-green); 
}
.Blue{
    border-bottom:6px solid var(--color-blue); 
}
/* .SquareCard:hover{
   
    transform: scale(1.005);

    
} */
