

.Link{
    position: relative;
    padding: 1.6rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    color: #949ca5;
    margin: .2rem 0;
    text-decoration: none;
    
}
.Link::before{
    content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        background-color: var(--color-green);
        transform: scaleY(0);
        transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s, background-color 0.1s;
}
.Link:hover::before{
    transform: scaleY(1);
    width: 100%;
}
.Link:hover{
    color: #fff;
}
.Title{
    
    font-family: 'Lato';
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 1.6rem;
    position: relative; 
    z-index: 10;
}
.Icon{
    font-size: 1.6rem;
    
    margin-right: 1.6rem;
    position: relative; 
    z-index: 10;

}

.LinkActive{
    background-color: var(--color-green);
    color: #fff;
}

.LinkActive::before {
    transform: scaleY(1);
    width: 100%;
}