.Screen {
    flex: 1;
    padding: 2rem;
    
    overflow-y: scroll;
    
}
.pdfContainer{
    padding: 1rem 0;
}
.CardContent {
    margin: 1rem;
    padding: 1rem;
}
.Button{
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 1.6rem;
    padding: 20px;
    margin: 5px;
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 17px 21px 66px -26px rgba(0,0,0,0.51);
    background-color: #4A00E0;
}

.dropDownContainer{
    display: flex;
}

.ContainerTitle {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: .1rem;
    padding-bottom: 1rem;
  }

  .checkBox {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: .1rem;
    padding-left: 3rem;
  }
  .loaderTitle{
    font-size: 2rem;
    font-family: 'Roboto';
    font-weight: 700;
    color: var(--color-grey-dark-1);
    margin-bottom: 4rem;
    letter-spacing: 1px;
    text-align: center;
  }
  .loaderContainer{
      height: 100px;
      width: 100px;
  }