.adminContainer{
    position: relative;
    flex: 1;
    padding: 2rem;
    overflow-y: scroll;
}
.mainContainer {
    position: relative;
    flex: 0 0 70%;
    height: 100%;
    background-color: white;
    box-shadow: var(--shadow-light);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 5rem;
}

.mainTitle {
    font-family: 'Roboto';
    font-size: 3rem;
    margin-bottom: 2rem;
}

/* TOP CONTROLS */

.topControlsContainer {
    display: flex;
    align-items: center;
}

.inputContainer {
    flex: 1;
    margin-right: 1rem;
}

.inputElement {
    font-family: 'Lato';
    color: var(--color-grey-dark-1);
    margin: .5rem 0;
    display: block;
    border-radius: 2px;
    border: 0;
    padding: 1rem 1rem;
    background-color: var(--color-grey-light-2);
    width: 100%;
    font-size: 1.5rem;
    border-bottom: 2px solid transparent;
}

.inputElement:focus {
    outline: none;
    border-bottom: 2px solid var(--color-strong-green);
}

/* TABLE */

.tableContainer {
    margin: 2rem 0;
    flex: 1;
    overflow-y: scroll;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.table th {
    font-size: 2rem;
    background-color: var(--color-strong-green);
    ;
    color: white;
    padding: 1rem;
}

.table tr:nth-child(even) {
    background-color: var( --color-grey-light-2);
}

.table td {
    font-size: 1.5rem;
    padding: 1rem;
}

.table td:not(:last-child) {
    border-right: 1px solid #ddd;
}

/* BOTTOM CONTROLS */

.bottomControlsContainer {
    justify-self: flex-end;
    display: flex;
    align-items: center;
}

/* LOADER */

.loaderContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.inputElement {
    font-family: 'Lato';
    color: var(--color-grey-dark-1);
    margin: 1rem 0;
    display: block;
    border-radius: 2px;
    border: 0;
    padding: 1rem 1rem;
    background-color: var(--color-grey-light-2);
    
    font-size: 1.5rem;
    border-bottom: 2px solid transparent;
    width: 100%;
}

.inputElement:focus {
    outline: none;
    border-bottom: 2px solid var(--color-strong-green);
}
.modalContainer{
    
}
.buttonContainer{
    display: flex;
    justify-content: flex-end;
}
.title{
    
    font-size: 2rem;
    font-family: 'Roboto';
    font-weight: 700;
    color: var(--color-grey-dark-1);
    margin-bottom: 2rem;
    letter-spacing: 1px;

}

.infoText{
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: var(--color-grey-dark-2);
    

}