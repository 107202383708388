.Screen {
    flex: 1;
    padding: 2rem;
    align-items: center;
    overflow-y: scroll;
    justify-content: center;
    display: flex;
}
.Holder{
    width: 50%;
    
}
.mainTitle {
    font-family: 'Roboto';
    font-size: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    font-weight: 400;
    color:var(--color-grey-dark-1);
    text-align: center;
    border-bottom: 1px solid var(--color-grey-light-4);
    
}
.container{
    padding: 3rem;
    display: flex;
    flex-direction: column;
    
}
.inputElement {
    font-family: 'Lato';
    color: var(--color-grey-dark-1);
    margin: 1rem 0;
    display: block;
    border-radius: 2px;
    border: 0;
    padding: 1rem 1rem;
    background-color: var(--color-grey-light-2);
    
    font-size: 1.5rem;
    border-bottom: 2px solid transparent;
    width: 100%;
}

.inputElement:focus {
    outline: none;
    border-bottom: 2px solid var(--color-strong-green);
}
.formContainer{
    flex: 1;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    align-self: center;
}
.form{
    width: 100%;
}

.buttonContainer{
    display: flex;
    justify-content: center;
}

.label{
    font-size: 1.4rem;
    color: var(--color-grey-dark-1);
    margin-bottom: .4rem;
    flex: 70%;
    
}
.input_holder {
    display: flex;
    align-items: center;
}