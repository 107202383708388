.Screen {
    flex: 1;
    padding: 2rem;
    overflow-y: scroll;
    
}

.HeadContainer{
    display: flex;
    flex: 1;
    justify-content: space-around;
  }
  .CardHolder{
   
    flex: 1;
    margin: 2rem;
  }
  
  .CardContent{
    flex:1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
  }
  .Icon{
    font-size: 4rem;
  }
  .Purple{
    color: var(--color-purple);
  }
  .Orange{
    color: var(--color-orange);
  }
  .Green{
    color: var(--color-green);
  }
  .HeadTitle{
    font-size: 3.5rem;
  }
  .CardText{
    color: var(--color-grey-dark-2);
    flex: 0 0 70%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: var(--line);
    
  }
  .TextDetail{
    text-align: center;
    font-size: 2rem;
  }
  .BoldText{
      font-weight: 700;
      color: var(--color-orange);
  }

  .ContentContainer{
      padding-top: 2rem;
  }

  .Summary{
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }

  /* TABLE */
.tableContainer {
    margin: 3rem 0;
    width:50%;
    /* overflow-y: scroll; */
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    
}


.table td:first-child {
    font-weight: 400;
    background-color: var(--color-orange);
    color: white;
    border: 1px solid #ddd;
}
.table td {
    
    font-size: 1.6rem;
    padding: .8rem;
    width: 50%;
    
}
.table td:last-child {
    border: 1px solid #ddd;
    background-color: white; 
}

.Title {

    font-size: 2rem;
    font-weight: 700;
    margin-bottom: .8rem;
    padding: .5rem;
  }

  .HeadFieldmanTable{
      display: flex;
      flex-direction: row;
      flex:1;
      margin-bottom: 0.8rem;
  }
  .HeadItem{
      flex:1;
      color: var(--color-grey-dark-1);
      font-size: 1.6rem;
  }

  .RowFieldmanTable{
    display: flex;
    flex-direction: row;
    flex:1;
    margin-bottom: 0.6rem;
    padding-bottom: .6rem;
    border-bottom: 1px solid #ddd;
}
.CardContentFieldman{
    flex:1;
    padding: 2rem;
    
}
.ContentItem{
    flex:1;
      color: var(--color-grey-dark-2);
      font-size: 1.4rem;
}
