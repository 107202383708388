.SideBarContainer {
    /* position: fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 180px; */
    flex: 0 0 14%;
    height: 100vh;
    background: linear-gradient(-180deg, var(--color-strong-green) 0%, var(--color-strong-green) 80%, var(--color-green)  100%);
    box-sizing: border-box;
    
}

.TopContainer {
    padding: 1.5rem;
    width: 100%;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.NavLinksWrapper {
    width: 100%;
    height: 30rem;
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-shrink: 0;
}

.Logo {
    background-color: var(--color-green);
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    box-shadow:  20px 20px 60px #19805c, 
             -20px -20px 60px #21ae7c;
}

.User {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 2rem;
    color: #f7f7f7;
    margin-top: 1rem;
}

.MenuLink {
    padding: 1.6rem;
    display: flex;
    color: #f7f7f7;
    font-family: 'Lato';
    letter-spacing: 2px;
    font-weight: 400;
    text-decoration: none;
}

.MyImg {
    max-width: 100%;
    max-height: 100%;
    padding: 1rem;
}

/* a:hover{

  background-color: #233645;
  color: #f7f7f7
} */