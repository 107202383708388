.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    
    border-radius: .8rem;
    box-shadow: var(--shadow-dark);
    padding: 2rem;
    left: 45%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    min-width: 30rem;
}

.title{
    font-size: 2rem;
    font-family: 'Roboto';
    font-weight: 700;
    color: var(--color-grey-dark-1);
    margin-bottom: 3rem;
    letter-spacing: 1px;
}

.infoText{
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--color-grey-dark-2);
    margin-bottom: 2rem;

}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
}
/* @media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
} */