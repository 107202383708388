/* TABLE */

.tableContainer {
    margin: 3rem 0;
    flex: 1;
    /* overflow-y: scroll; */
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.table th {
    font-size: 1.6rem;
    background-color: var(--color-strong-green);
    
    color: white;
    padding: .8rem;
}

.table tr:nth-child(even) {
    background-color: var( --color-grey-light-2);
}

.table td {
    font-size: 1.6rem;
    padding: .8rem;
}

/* .table td:not(:last-child) {
    border-right: 1px solid #ddd;
} */

.table td {
    border: 1px solid #ddd;
}