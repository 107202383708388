.Button{
    position: -webkit-sticky;
    position: sticky;
    bottom: 4rem;
    left: 200rem;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    font-size: 5rem;
    
    
    font-weight: 400;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    text-align: center;
    box-shadow: 17px 21px 66px -26px rgba(0,0,0,0.51);
}

.Succes{
    background-color: var(--color-strong-green);
}

.Save{
 background-color: #4A00E0;
}
.Orange{
    background-color: var(--color-orange);
   }
.Danger{
    background-color: #ED213A;
}
.Blue{
    background-color: var(--color-blue);
}
.Button:hover{
    opacity: .8;
}