.fullLogincontainer{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--color-blue);
    align-items: center;
    justify-content: center;
}
.loginContainer{
    flex: 0 0 40%;
    background-color: white;
    
    box-shadow: var(--shadow-light);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 5rem;
    
    align-items: center;
    justify-content: stretch;
}
.loginTitleContainer{
    padding: 2rem 0;
}
.title{
    font-size: 2.6rem;
    font-weight: 700;
}
.inputElement {
    font-family: 'Lato';
    color: var(--color-grey-dark-1);
    margin: .5rem 0;
    display: block;
    border-radius: 2px;
    border: 0;
    padding: 1rem 1rem;
    background-color: var(--color-grey-light-2);
    
    font-size: 1.5rem;
    border-bottom: 2px solid transparent;
    width: 100%;
}

.inputElement:focus {
    outline: none;
    border-bottom: 2px solid var(--color-strong-green);
}
.formContainer{
    flex: 1;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}
.form{
    width: 100%;
}
.buttonContainer{
    display: flex;
    justify-content: center;
}



.Logo {
    
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    
}

.MyImg {
    max-width: 100%;
    max-height: 100%;
    
}