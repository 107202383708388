.manifoldScreen {
  position: relative;
  flex: 1;
  padding: 2rem;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
}
.Holder {
  width: 100%;
  height: 90%;
}
.mainTitle {
  font-family: "Roboto";
  font-size: 3rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
  font-weight: 400;
  color: var(--color-grey-dark-1);
  text-align: center;
  border-bottom: 1px solid var(--color-grey-light-4);
}
.container {
  padding: 3rem;
  display: flex;
  flex-direction: column;
}
.inputElement {
  font-family: "Lato";
  color: var(--color-grey-dark-1);
  margin: 1rem 0;
  display: block;
  border-radius: 2px;
  border: 0;
  padding: 1rem 1rem;
  background-color: var(--color-grey-light-2);

  font-size: 1.5rem;
  border-bottom: 2px solid transparent;
  width: 100%;
}

.inputElement:focus {
  outline: none;
  border-bottom: 2px solid var(--color-strong-green);
}
.formContainer {
  flex: 1;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  align-self: center;
}
.form {
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.label {
  font-size: 1.4rem;
  color: var(--color-grey-dark-1);
  margin-bottom: 0.4rem;
  flex: 70%;
}
.input_holder {
  display: flex;
  align-items: center;
}

.label {
  font-family: "Lato";
  color: var(--color-grey-dark-1);
  margin: 1rem 0;
  font-size: 1.5rem;
  width: 100px;
}

.selectorHolder {
  display: flex;
  flex-direction: "row";
}
.filePicker {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.labelPicker {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  padding: 1rem 2rem;

  font-weight: 400;
  border-radius: 5px;
  box-shadow: 17px 21px 66px -26px rgba(0, 0, 0, 0.51);

  background-color: #0b7751;
  transition: all ease-out 0.2s;
}
.imgPreviewContainer {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
